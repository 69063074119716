<template>
  <div class="sta">
    <div class="sta_item">
      <p class="sta_item_title">项目总览</p>
      <div class="sta_item_f">
        <div class="sta_item_p1">
          <div class="sta_item_p1_item">
            <p>项目</p>
            <p class="skyblue">{{ from1.projectStatistic.total }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>自营</p>
            <p class="skyblue">{{ from1.projectStatistic.自营 }}</p>
          </div>
          <div class="sta_item_p1_item">
            <div class="ag"><span class="agent">代理</span></div>
            <p class="skyblue">{{ from1.projectStatistic.代理 }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>未计划</p>
            <p class="skyblue">{{ from1.projectStatistic.未计划 }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>运维中</p>
            <p class="skyblue">{{ from1.projectStatistic.运维中 }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>已竣工</p>
            <p class="skyblue">{{ from1.projectStatistic.已竣工 }}</p>
          </div>
        </div>
        <div class="sta_item_p">
          <p class="icon_title">
            <img src="../assets/icon/13.png" alt="" />
            累计销售
            <span class="gray999">（含渠道）</span>
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.sale }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月销售 <span class="skyblue">￥{{ from.currentSale }}</span>
            </p>
            <p>
              环比
              <span class="colorgreen" v-if="from.rate > 0"
                >+{{ from.rate }}% ↑</span
              >
              <span class="colorred" v-else-if="from.rate < 0">
                {{ from.rate }}% ↓</span
              >
              <span v-else-if="from.rate == '-'"> {{ from.rate }} </span>
              <span v-else>0</span>
            </p>
          </div>
        </div>
        <div class="sta_item_p">
          <p class="icon_title">
            <img src="../assets/icon/8.png" alt="" />
            累计回款
            <span class="gray999">（含渠道）</span>
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.repaymentAmount }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月回款
              <span class="skyblue">￥{{ from.repaymentNowAmount }}</span>
            </p>
            <p>
              环比
              <span class="colorgreen" v-if="from.repaymentQoq > 0"
                >+{{ from.repaymentQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.repaymentQoq < 0">
                {{ from.repaymentQoq }}% ↓</span
              >
              <span v-else-if="from.repaymentQoq == '-'">
                {{ from.repaymentQoq }}
              </span>
              <span v-else>0</span>
            </p>
          </div>
        </div>
        <div class="sta_item_p">
          <p class="icon_title">
            <img src="../assets/icon/7.png" alt="" />
            应收账款
            <span class="gray999">（含渠道）</span>
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.receivableAmount }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月新增
              <span class="skyblue">￥{{ from.receivableNowAmount }}</span>
            </p>
            <p>
              环比
              <span class="colorgreen" v-if="from.receivableQoq > 0"
                >+{{ from.receivableQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.receivableQoq < 0">
                {{ from.receivableQoq }}% ↓</span
              >
              <span v-else-if="from.receivableQoq == '-'">
                {{ from.receivableQoq }}
              </span>
              <span v-else>0</span>
            </p>
          </div>
        </div>
        <div class="sta_item_p">
          <div class="icon_title1">
            <p><img src="../assets/icon/6.png" alt="" /> 应收排行</p>
            <span @click="modal=3;getData3()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from1.应收排行.slice(0, 3)" style="width: 100%">
            <el-table-column prop="name" label="销售经理">
              <template slot-scope="scope" width="100">
                {{ scope.$index + 1 + "." + scope.row.creatorName }}
              </template>
            </el-table-column>
            <el-table-column prop="receivablesNum" width="80" label="应收项目">
            </el-table-column>
            <el-table-column
              width="100"
              :show-overflow-tooltip="true"
              label="应收金额"
            >
              <template slot-scope="scope">
                {{ "￥" + fmoney(scope.row.receivables) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="sta_item">
      <p class="sta_item_title">采购与付款</p>
      <div class="sta_item_f">
        <div class="sta_item_p1">
          <div class="sta_item_p1_item">
            <p>采购计划</p>
            <p class="skyblue">{{ from.procureTotal }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>待审批</p>
            <p class="skyblue">{{ from.procureExam }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>待付款</p>
            <p class="skyblue">{{ from.procurePay }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>付款计划</p>
            <p class="skyblue">{{ from.paymentTotal }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>待审批</p>
            <p class="skyblue">{{ from.paymentExam }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>待付款</p>
            <p class="skyblue">{{ from.paymentPay }}</p>
          </div>
        </div>
        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/10.png" alt="" />
            累计采购金额
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.procureAmount }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月采购<span class="skyblue">￥{{ from.procureNowAmount }}</span>
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.procureQoq > 0"
                >+{{ from.procureQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.procureQoq < 0">
                {{ from.procureQoq }}% ↓</span
              >
              <span v-else-if="from.procureQoq == '-'">
                {{ from.procureQoq }}
              </span>
              <span v-else>0</span>
            </p>
          </div>
        </div>

        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/7.png" alt="" />
            已付金额
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.procurePaidAmount }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月已付<span class="skyblue">￥{{ from.procurePaidNowAmount }}</span>
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.procurePaidQoq > 0"
                >+{{ from.procurePaidQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.procurePaidQoq < 0">
                {{ from.procurePaidQoq }}% ↓</span
              >
              <span v-else-if="from.procurePaidQoq == '-'">
                {{ from.procurePaidQoq }}
              </span>
            </p>
            <!-- <span v-else>0</span> -->
          </div>
        </div>
        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/7.png" alt="" />
            待付金额
          </p>
          <p class="sta_item_p_num skyblue">
            ￥{{ from.procureBalanceAmount }}
          </p>
          <div class="sta_item_p_bottom">
            <p>
              本月待付<span class="skyblue">￥{{ from.procureBalanceNowAmount }}</span>
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.procureBalanceQoq > 0"
                >+{{ from.procureBalanceQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.procureBalanceQoq < 0">
                {{ from.procureBalanceQoq }}% ↓</span
              >
              <span v-else-if="from.procureBalanceQoq == '-'">
                {{ from.procureBalanceQoq }}
              </span>
            </p>
            <!-- <span v-else>0</span> -->
          </div>
        </div>
        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/11.png" alt="" />
            累计 商务/其他 付款金额
          </p>
          <p class="sta_item_p_num skyblue">￥{{ from.paymentOtherAmount }}</p>
          <div class="sta_item_p_bottom">
            <p>
              本月累计<span class="skyblue">￥{{ from.paymentOtherNowAmount }}</span>
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.paymentOtherQoq > 0"
                >+{{ from.paymentOtherQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.paymentOtherQoq < 0">
                {{ from.paymentOtherQoq }}% ↓</span
              >
              <span v-else-if="from.paymentOtherQoq == '-'">
                {{ from.paymentOtherQoq }}
              </span>
            </p>
            <!-- <span v-else>0</span> -->
          </div>
        </div>

        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/7.png" alt="" />
            已付金额
          </p>
          <p class="sta_item_p_num skyblue">
            ￥{{ from.paymentOtherPaidAmount }}
          </p>
          <div class="sta_item_p_bottom">
            <p>
              本月已付<span class="skyblue"
                >￥{{ from.paymentOtherPaidNowAmount }}</span
              >
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.paymentOtherPaidQoq > 0"
                >+{{ from.paymentOtherPaidQoq }}% ↑</span
              >
              <span class="colorred" v-else-if="from.paymentOtherPaidQoq < 0">
                {{ from.paymentOtherPaidQoq }}% ↓</span
              >
              <span v-else-if="from.paymentOtherPaidQoq == '-'">
                {{ from.paymentOtherPaidQoq }}
              </span>
              <span v-else-if="from.paymentOtherPaidQoq == '0'">0</span>
            </p>
          </div>
        </div>

        <div class="sta_item_p30">
          <p class="icon_title">
            <img src="../assets/icon/7.png" alt="" />
            待付金额
          </p>
          <p class="sta_item_p_num skyblue">
            ￥{{ from.paymentOtherBalanceAmount }}
          </p>
          <div class="sta_item_p_bottom">
            <p>
              本月待付<span class="skyblue"
                >￥{{ from.paymentOtherBalanceNowAmount }}</span
              >
            </p>
            <p>
              环比<span class="colorgreen" v-if="from.paymentOtherBalanceQoq > 0"
                >+{{ from.paymentOtherBalanceQoq }}% ↑</span
              >
              <span
                class="colorred"
                v-else-if="from.paymentOtherBalanceQoq < 0"
              >
                {{ from.paymentOtherBalanceQoq }}% ↓</span
              >
              <span v-else-if="from.paymentOtherBalanceQoq == '-'">
                {{ from.paymentOtherBalanceQoq }}
              </span>
              <span v-else-if="from.paymentOtherBalanceQoq == '0'">0</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="sta_item">
      <p class="sta_item_title">计划与材料</p>
      <div class="sta_item_f">
        <div class="sta_item_p2">
          <div class="sta_item_p1_item">
            <p>累计计划</p>
            <p class="skyblue">{{ from1.计划统计.ljjh }}</p>
          </div>
          <div class="sta_item_p1_item">
            <p>本月计划 <img src="../assets/icon/16.png" alt="" /></p>
            <p>
              <span class="skyblue">{{ from1.计划统计.byjh }}</span>
              <span class="colorgreen" v-if="from1.计划统计.hb > 0"
                >+{{ from1.计划统计.hb }}% ↑</span
              >
              <span class="colorred" v-else-if="from1.计划统计.hb < 0">
                {{ from1.计划统计.hb }}% ↓</span
              >
              <span v-else-if="from1.计划统计.hb == '-'">
                {{ from1.计划统计.hb }}
              </span>
            </p>
          </div>

          <div style="margin-top: 14px; min-width: 240px; padding-left: 20px">
            <el-progress
              :text-inside="true"
              stroke-linecap="butt"
              :stroke-width="16"
              :percentage="from1.percentage-0"
            ></el-progress>
            <p style="margin-top: 16px">
              <span class="spa">已完成 </span>
              <span class="colorgreen" style="margin-right: 100px">{{
                from1.计划统计.ywc
              }}</span>
              <span class="spa">未完成 </span>
              <span class="colorred">{{ from1.计划统计.wwc }}</span>
            </p>
          </div>
        </div>
        <div class="sta_item_p">
          <div class="icon_title1">
            <p>
              <img src="../assets/icon/15.png" alt="" /> 今日实施（{{
                from1.今日实施.length
              }}）
            </p>
            <span @click="modal=7;getData7()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from1.今日实施.slice(0, 3)" style="width: 100%">
            <el-table-column
              prop="name"
              label="项目名称"
              width="130"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 + "." + scope.row.projectName }}
              </template>
            </el-table-column>
            <el-table-column prop="type" width="70" label="类型">
            </el-table-column>
            <el-table-column
              prop="status"
              width="70"
              :show-overflow-tooltip="true"
              label="状态"
            ></el-table-column>
          </el-table>
        </div>
        <div class="sta_item_p">
          <div class="icon_title1">
            <p><img src="../assets/icon/6.png" alt="" /> 运维次数排行</p>
            <span @click="modal=5;getData5()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from1.运营排行.slice(0, 3)" style="width: 100%">
            <el-table-column
              prop="name"
              label="项目名称"
              width="130"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span class="colorred"
                  >{{ scope.$index + 1 + "." + scope.row.projectName }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="num" width="60" label="次数">
            </el-table-column>
            <el-table-column
              prop="creatorName"
              width="80"
              :show-overflow-tooltip="true"
              label="销售经理"
            ></el-table-column>
          </el-table>
        </div>
        <div class="sta_item_p">
          <div class="icon_title1">
            <p><img src="../assets/icon/6.png" alt="" /> 材料消耗排行</p>
            <span @click="modal=4;getData4()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from1.材料消耗.slice(0, 3)" style="width: 100%">
            <el-table-column
              prop="name"
              label="材料名称"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope" width="110">
                {{ scope.$index + 1 + "." + scope.row.productName }}
              </template>
            </el-table-column>
            <el-table-column prop="num" width="60" label="总消耗">
            </el-table-column>
            <el-table-column
              prop="price"
              width="110"
              :show-overflow-tooltip="true"
              label="本月消耗"
            >
              <template slot-scope="scope" width="110">
                {{ scope.row.currentMonth }}
                <span class="colorgreen" v-if="scope.row.hb > 0"
                  >+{{ scope.row.hb }}% ↑</span
                >
                <span class="colorred" v-else-if="scope.row.hb < 0">
                  {{ scope.row.hb }}% ↓</span
                >
                <span v-else-if="scope.row.hb == '-'">
                  {{ scope.row.hb }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="sta_item_p">
          <div class="icon_title1">
            <p><img src="../assets/icon/17.png" alt="" /> 库存预警</p>
            <span @click="modal=1;getData1()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from.productWarn" style="width: 100%">
            <el-table-column
              prop="name"
              label="材料名称"
              :show-overflow-tooltip="true"
              width="200"
            >
              <template slot-scope="scope">
                <span class="colorred">{{
                  scope.$index + 1 + "." + scope.row.name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="stock" width="70" label="库存数量">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="sta_item1">
      <p class="sta_item_title">供应与代理</p>
      <div class="sta_item_f">
        <div class="sta_item_p3">
          <div class="sta_item_p3_item">
            <div class="sta_item_p1_item">
              <p>供应商</p>
              <p class="skyblue">{{ from1.供应商和代理商.supplier }}</p>
            </div>
            <div class="sta_item_p1_item">
              <p>累计供货次数</p>
              <p class="skyblue">{{ from1.供应商和代理商.planCount }}</p>
            </div>
            <div class="sta_item_p1_item"></div>
            <div class="sta_item_p1_item"></div>
          </div>
          <div class="sta_item_p3_item">
            <div class="sta_item_p1_item">
              <p>代理商</p>
              <p class="skyblue">{{ from1.供应商和代理商.proxy }}</p>
            </div>
            <div class="sta_item_p1_item">
              <p>渠道计划</p>
              <p class="skyblue">{{ from1.供应商和代理商.qdjh }}</p>
            </div>
            <div class="sta_item_p1_item">
              <p>我方实施</p>
              <p class="skyblue">{{ from1.供应商和代理商.wfss }}</p>
            </div>
            <div class="sta_item_p1_item">
              <p>自提/发货</p>
              <p class="skyblue">{{ from1.供应商和代理商.zt }}</p>
            </div>
          </div>
        </div>
        <div class="sta_item_p4">
          <div class="icon_title1">
            <p>
              <img src="../assets/icon/14.png" alt="" /> 供应商应付
              <span class="colorred" style="font-weight: 600; margin-left: 8px"
                >￥{{ fmoney(from.merchantAllBalance) }}</span
              >
            </p>
            <span @click="modal=2;getData2()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table :data="from.merchantProcureList" style="width: 100%">
            <el-table-column
              prop="merchantName"
              label="供应商"
              :show-overflow-tooltip="true"
              width="120"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 + "." + scope.row.merchantName }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantCount" label="采购计划">
            </el-table-column>
            <el-table-column prop="merchantTotal" label="采购总额">
              <template slot-scope="scope" width="00">
                {{ "￥" + fmoney(scope.row.merchantTotal) }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantPaid" label="已付金额">
              <template slot-scope="scope" width="00">
                {{
                  "￥" + fmoney(scope.row.merchantPaid)
                }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantBalance" label="应付金额">
              <template slot-scope="scope" width="00">
                {{
                  "￥" + fmoney(scope.row.merchantBalance)
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="sta_item_p4">
          <div class="icon_title1">
            <p>
              <img src="../assets/icon/7.png" alt="" /> 代理商应收
              <span
                class="colorgreen"
                style="font-weight: 600; margin-left: 8px"
                >￥{{ fmoney(from1.代理商应收.zys) }}</span
              >
            </p>
            <span @click="modal=6;getData6()" class="colorblue"
              >查看全部 ></span
            >
          </div>
          <el-table
            :data="from1.代理商应收.list.slice(0, 3)"
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              label="代理商"
              width="120"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 + "." + scope.row.merchantName }}
              </template>
            </el-table-column>
            <el-table-column prop="ss" label="实施/发货"> </el-table-column>
            <el-table-column prop="sum" label="计划总额">
              <template slot-scope="scope">
                {{ "￥" + fmoney(scope.row.sum) }}
              </template>
            </el-table-column>
            <el-table-column prop="yfje" label="已付金额">
              <template slot-scope="scope">
                {{ "￥" + fmoney(scope.row.yfje) }}
              </template>
            </el-table-column>
            <el-table-column label="应收金额">
              <template slot-scope="scope">
                {{ "￥" + fmoney(scope.row.ysje) }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
     <!-- 库存预警排行 -->
    <div class="modal5" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           库存预警排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData1"
          style="width: 98%"
          border
        >
          <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage1 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="name" label="名称"> </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">硬件材料</span>
              <span v-if="scope.row.type == 2">软件系统</span>
              <span v-if="scope.row.type == 3">其他服务</span>
              <span v-if="scope.row.type == 4">商务名目</span>
              <span v-if="scope.row.type == 5">组合包</span>
            </template>
          </el-table-column>
          <el-table-column prop="stock" label="库存"> 
            <template slot-scope="scope">
              <span class="colorblue" style="cursor: pointer;" @click="currentProductId=scope.row.id;getDetail()">{{scope.row.stock}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="admonition" label="产品预警数量">
          </el-table-column>
        </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 供应商应付 -->
    <div class="modal5" v-show="modal == 2">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           供应商应付排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                     <el-table :data="tableData2" style="width: 100%">
                       <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage2 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
            <el-table-column
              prop="merchantName"
              label="供应商"
              :show-overflow-tooltip="true"
              width="240"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 + "." + scope.row.merchantName }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantCount" label="采购计划">
            </el-table-column>
            <el-table-column prop="merchantTotal" label="采购总额">
              <template slot-scope="scope">
                {{ "￥" + scope.row.merchantTotal }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantPaid" label="已付金额">
              <template slot-scope="scope" >
                {{
                  "￥" + scope.row.merchantPaid
                }}
              </template>
            </el-table-column>
            <el-table-column prop="merchantBalance" label="应付金额">
              <template slot-scope="scope" >
                {{
                  "￥" + scope.row.merchantBalance
                }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
           <div class="money">
        <p>
          <span class="">本页采购总金额：</span>
          <span class="colorblue">￥{{ fmoney(form2.merchantCurrentTotal) }}</span>
        </p>
        <p>
          <span>本页已付金额：</span><span class="colorgreen">￥{{ fmoney(form2.merchantCurrentPaid) }}</span>
        </p>
        <p>
          <span>本页应付金额：</span><span class="colorred">￥{{ fmoney(form2.merchantCurrentBalance) }}</span>
        </p>
        <p>
          <span>累计采购总金额：</span><span class="colorblue">￥{{ fmoney(from.merchantAllTotal) }}</span>
        </p>
        <p>
          <span>累计已付金额：</span><span class="colorgreen">￥{{ fmoney(from.merchantAllPaid) }}</span>
        </p>
        <p>
          <span>累计应付金额：</span><span class="colorred">￥{{ fmoney(from.merchantAllTotal||0-from.merchantAllPaid||0) }}</span>
        </p>

      </div>
          <el-pagination
            @current-change="handleCurrentChange2"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total2"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 应收排行 -->
    <div class="modal5" v-show="modal == 3">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           应收排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData3"
          style="width: 98%"
          border
        >
          <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage3 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
              <el-table-column prop="creatorName" label="销售经理">
            </el-table-column>
            <el-table-column prop="receivablesNum"  label="应收项目">
            </el-table-column>
            <el-table-column
             
              :show-overflow-tooltip="true"
              label="应收金额"
            >
              <template slot-scope="scope">
                {{ "￥" + fmoney(scope.row.receivables) }}
              </template>
            </el-table-column>
        </el-table>
        </div>
        <div class="block">
             <div class="money">
        <p>
          <span class="">本页应收金额：</span>
          <span class="colorred">￥{{ fmoney(form3.page_zys) }}</span>
        </p>
        <p>
          <span>本页累计项目数：</span
          ><span class="colorblue">{{ form3.page_zys_pro }}</span>
        </p>
        <p>
          <span>累计应收金额：</span
          ><span class="colorred">￥{{ fmoney(form3.zys) }}</span>
        </p>
        <p>
          <span>累计应收项目数：</span
          ><span class="colorblue">{{ form3.zys_pro }}</span>
        </p>
      </div>
          <el-pagination
            @current-change="handleCurrentChange3"
            :current-page="currentPage3"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total3"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 材料消耗 -->
    <div class="modal5" v-show="modal == 4">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           材料消耗排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData4"
          style="width: 98%"
          border>
        <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage4 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
            <el-table-column
              prop="productName"
              label="材料名称"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="num"  label="总消耗">
            </el-table-column>
            <el-table-column
              prop="price"
              :show-overflow-tooltip="true"
              label="本月消耗"
            >
              <template slot-scope="scope" >
                {{ scope.row.currentMonth }}
                <span class="colorgreen" v-if="scope.row.hb > 0"
                  >+{{ scope.row.hb }}% ↑</span
                >
                <span class="colorred" v-else-if="scope.row.hb < 0">
                  {{ scope.row.hb }}% ↓</span
                >
                <span v-else-if="scope.row.hb == '-'">
                  {{ scope.row.hb }}
                </span>
              </template>
            </el-table-column>
        </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange4"
            :current-page="currentPage4"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total4"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 运维次数 -->
    <div class="modal5" v-show="modal == 5">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           运维次数排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData5"
          style="width: 98%"
          border>
        <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage5 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
            <el-table-column
              prop="projectName"
              label="项目名称"
              width="500"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="num"  label="运维次数">
            </el-table-column>
            <el-table-column
              prop="creatorName"
              :show-overflow-tooltip="true"
              label="销售经理"
            ></el-table-column>
        </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange5"
            :current-page="currentPage5"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total5"
          >
          </el-pagination>
        </div>
      </div>
    </div>
     <!-- 代理商应收 -->
    <div class="modal5" v-show="modal == 6">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           代理商应收排行
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData6"
          style="width: 98%"
          border>
        <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage6 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
           <el-table-column
              prop="merchantName"
              label="代理商"
              width="400"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="ss" label="实施/发货"> </el-table-column>
            <el-table-column prop="sum" label="计划总额">
              <template slot-scope="scope">
                {{ "￥" + scope.row.sum }}
              </template>
            </el-table-column>
            <el-table-column prop="yfje" label="已付金额">
              <template slot-scope="scope">
                {{ "￥" + scope.row.yfje }}
              </template>
            </el-table-column>
            <el-table-column label="应收金额">
              <template slot-scope="scope">
                {{ "￥" + scope.row.ysje }}
              </template>
            </el-table-column>
        </el-table>
        </div>
        <div class="block">
                     <div class="money">
        <p>
          <span class="">本页计划总金额：</span>
          <span class="colorblue">￥{{ fmoney(form6.this_zje) }}</span>
        </p>
        <p>
          <span>本页已付金额：</span><span class="colorgreen">￥{{ fmoney(form6.this_zyf) }}</span>
        </p>
        <p>
          <span>本页应收金额：</span><span class="colorred">￥{{ fmoney(form6.this_zys) }}</span>
        </p>
        <p>
          <span>累计计划总金额：</span><span class="colorblue">￥{{ fmoney(form6.zje) }}</span>
        </p>
        <p>
          <span>累计已付金额：</span><span class="colorgreen">￥{{ fmoney(form6.zyf) }}</span>
        </p>
        <p>
          <span>累计应收金额：</span><span class="colorred">￥{{ fmoney(form6.zys) }}</span>
        </p>

      </div>
          <el-pagination
            @current-change="handleCurrentChange6"
            :current-page="currentPage6"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total6"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 今日实施 -->
    <div class="modal5" v-show="modal == 7">
      <div class="inner">
        <p class="title">
          <span class="title_item1">
           今日实施
          </span>
          <span
            @click="
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-circle-close"></i>
          </span>
        </p>
        <div class="table">
                <el-table
          :data="tableData7"
          style="width: 98%"
          border>
        <el-table-column type="index" label="序号" width="80">
            <template slot-scope="scope">
              {{ (currentPage7 - 1) * 10 + scope.$index + 1 }}
            </template>
          </el-table-column>
           <el-table-column
              prop="projectName"
              label="项目名称"
              width="500"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="creatorName"  label="销售人员">
            </el-table-column>
            <el-table-column prop="type"  label="类型">
            </el-table-column>
            <el-table-column
              prop="status"
              :show-overflow-tooltip="true"
              label="状态"
            ></el-table-column>
            <el-table-column prop="handsPersonName"  label="实施人员">
            </el-table-column>
        </el-table>
        
        </div>
        
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange7"
            :current-page="currentPage7"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total7"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { totalAnalysis, totalstatistics,inventoryWarnList,
getMerchantProcureList,ys,cl,yw,dlys,ss
 } from "@/api/apis.js";
 import {fmoney} from  "@/utils/getTime.js";
export default {
  data() {
    return {
      from: {},
      modal:0,
      currentPage1:1,   
         fmoney,
      total1:0,
      tableData1:[],
      currentPage2:1,
      total2:0,
      form2:{},
      tableData2:[],
      currentPage3:1,
      total3:0,
      form3:{},
      tableData3:[],
       currentPage4:1,
      total4:0,
      tableData4:[],
      currentPage5:1,
      total5:0,
      tableData5:[],
      currentPage6:1,
      total6:0,
      tableData6:[],
      form6:{},
      currentPage7:1,
      total7:0,
      tableData7:[],
      from1: {
        projectStatistic: {},
        今日实施: [],
        代理商应收: { list: [] },
        供应商和代理商: {},
        库存预警: {},
        计划统计: {},
        应收排行: [],
        运营排行: [],
        材料消耗: [],
      },
    };
  },
  methods: {
    //库存预警
    getData1(){
      inventoryWarnList({pageSize:10,pageNum:this.currentPage1,}).then(res=>{
        if(res.code==200){
           this.tableData1=res.data.list
        this.total1=res.data.total-0
      }else{
        this.tableData1=[]    
        this.total1=0 
      }
      })
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.getData1();
    },
     //供应商应付
    getData2(){
      getMerchantProcureList({pageSize:10,pageNum:this.currentPage2,}).then(res=>{
        if(res.code==200){
           this.tableData2=res.data.list.list
        this.total2=res.data.list.total-0
        this.form2=res.data
        console.log(this.form2,this.tableData2);
        
      }else{
        this.tableData2=[]    
        this.total2=0
        this.form2={} 
      }
      })
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.getData2();
    },
     //应收列表
    getData3(){
      ys({pageSize:10,pageNum:this.currentPage3,}).then(res=>{
        if(res.code==200){
           this.tableData3=res.data.page.list
            this.total3=res.data.page.total-0
            this.form3=res.data
      }else{
        this.tableData3=[]    
        this.total3=0
        this.form3={}
      }
      })
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val;
      this.getData3();
    },
     //材料消耗
    getData4(){
      cl({pageSize:10,pageNum:this.currentPage4,}).then(res=>{
        if(res.code==200){
           this.tableData4=res.data.page.list
        this.total4=res.data.page.total-0
      }else{
        this.tableData4=[]    
        this.total4=0 
      }
      })
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val;
      this.getData4();
    },
       //运维次数
    getData5(){
      yw({pageSize:10,pageNum:this.currentPage5,}).then(res=>{
        if(res.code==200){
           this.tableData5=res.data.page.list
        this.total5=res.data.page.total-0
      }else{
        this.tableData5=[]    
        this.total5=0 
      }
      })
    },
    handleCurrentChange5(val) {
      this.currentPage5 = val;
      this.getData5();
    },
         //代理商应收
    getData6(){
      dlys({pageSize:10,pageNum:this.currentPage6,}).then(res=>{
        if(res.code==200){
           this.tableData6=res.data.page.list
        this.total6=res.data.page.total-0
        this.form6=res.data
      }else{
        this.tableData6=[]    
        this.total6=0 
        this.form6={}
      }
      })
    },
    handleCurrentChange6(val) {
      this.currentPage6 = val;
      this.getData6();
    },
            //今日实施
    getData7(){
      ss({pageSize:10,pageNum:this.currentPage7,}).then(res=>{
        if(res.code==200){
           this.tableData7=res.data.list
        this.total7=res.data.total-0
      }else{
        this.tableData7=[]    
        this.total7=0 
      }
      })
    },
    handleCurrentChange7(val) {
      this.currentPage7 = val;
      this.getData7();
    },
  },
  mounted() {
    //获取权限
    // let arr = JSON.parse(localStorage.getItem('perArr'))
    // let routePath =this.$route.path
    // arr.forEach(item=>{
    //   if(item.purl==routePath){
    //     this.permissoin.push(item.permission)
    //   }
    // })

    totalAnalysis().then((res) => {
      this.from = res.data;
    });
    totalstatistics().then((res) => {
      this.from1 = res.data;
      this.from1.percentage =
        (
          (res.data.计划统计.ywc * 100) /
          (res.data.计划统计.ywc + res.data.计划统计.wwc)
        ).toFixed(2) || 0;
    });
  },
};
</script>

<style lang="less" >
.sta {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .money{
    margin-bottom: 20px;
    margin-left: 20px;
    display: flex;
     p {
        // width: 160px;
        margin-right: 30px;
        color: #606266;
        span {
          font-weight: 600;
        }
      }
  }
    .modal5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 101;
    display: flex;
    align-items: center;
    .inner {
      width: 1450px;
      height: 790px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      .title {
        color: #2b2e36;
        line-height: 50px;
        font-weight: bold;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #dddddd;
        i {
          font-size: 18px;
          font-weight: 600;
        }
      }
      .choice_item {
        margin: 22px 0;
        padding-left: 20px;
        .btn2 {
          width: 60px;
          height: 30px;
          background-color: #15bb9b;
          color: #fff;
          border-radius: 4px;
          margin-left: 20px;
          cursor: pointer;
          i {
            font-size: 12px;
            position: relative;
            top: -1px;
          }
        }

        .btn1 {
          width: 60px;
          height: 30px;
          background-color: #0090ff;
          color: #fff;
          border-radius: 4px;
          margin-left: 20px;
          cursor: pointer;
          i {
            font-size: 12px;
            position: relative;
            top: -1px;
          }
        }
        .btn3 {
          width: 60px;
          height: 30px;
          background-color: #fea900;
          color: #fff;
          border-radius: 4px;
          margin-left: 20px;
          cursor: pointer;
          i {
            font-size: 12px;
            position: relative;
            top: -1px;
          }
        }
        .el-select .el-input {
          width: 114px;
          margin-left: 20px;
        }
      }
      .table {
        padding: 0 0px 0 22px;
        flex: 1;
        overflow: auto;
        &::-webkit-scrollbar-track-piece {
          width: 6px;
          height: 6px;
          background-color: #f0f0f0;
        }
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: #00c0ff;
        }
        &::-webkit-scrollbar-thumb {
          width: 6px;
          height: 6px;
          background-color: #cccccc;
          border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
          width: 6px;
          background-color: #999999;
        }
        .el-table {
          button {
            i {
              margin-left: 2px;
            }
          }
        }
      }
      .block {
        .el-pagination {
          padding: 10px 18px;
        }
      }
    }
  }
  .sta_item,
  .sta_item1 {
    width: 100%;
    height: 23.5%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .skyblue {
      color: #475669;
      font-weight: 600;
    }
    .colorblue {
      cursor: pointer;
    }
    .icon_title1 {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;

      img {
        vertical-align: middle;
        margin-right: 4px;
        position: relative;
        top: -2px;
      }
    }
    .el-table .cell {
      line-height: 26px;
    }
    .el-table td {
      padding: 2px 0 !important;
    }
    .el-table {
      font-size: 12px;
    }
    .el-table th {
      background-color: #fff;
      padding: 0px;
    }
    .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
  }
  .sta_item_title {
    font-size: 12px;
    font-weight: 550;
    line-height: 32px;
    border-bottom: 1px solid #ebf0f5;
    padding-left: 20px;
  }
  .sta_item_f {
    font-size: 12px;
    flex: 1;
    display: flex;
    padding: 6px 0;
    .sta_item_p1 {
      border-right: 1px solid #ebf0f5;
      width: 18%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .sta_item_p1_item {
        width: 28%;
        height: 45%;

        p {
          text-align: center;
          line-height: 30px;
        }
        .skyblue {
          font-size: 14px;
        }
        .ag {
          text-align: center;
          height: 30px;
        }
        .agent {
          display: inline-block;
          background-color: #475669;
          color: #fff;
          height: 24px;
          width: 30px;
          line-height: 24px;
          border-radius: 4px;
          margin-top: 4px;
        }
      }
    }
    .icon_title1 {
      margin: 17px 0 10px 0;
    }
    .sta_item_p {
      width: 20.5%;
      height: 100%;
      font-size: 12px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .icon_title {
        img {
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -2px;
        }
      }

      .sta_item_p_num {
        font-size: 30px;
      }
      .sta_item_p_bottom {
        display: flex;
        justify-content: space-between;
      }
    }
    .sta_item_p30 {
      width: 13.6%;
      height: 100%;
      font-size: 12px;
      padding: 0 2px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .icon_title {
        img {
          vertical-align: middle;
          margin-right: 4px;
          position: relative;
          top: -2px;
        }
      }

      .sta_item_p_num {
        font-size: 30px;
      }
      .sta_item_p_bottom {
        display: flex;
        justify-content: space-around;
        
        // & > p:nth-child(1) {
        //   margin-right: 30px;
        // }
      }
    }
    .sta_item_p2 {
      border-right: 1px solid #ebf0f5;
      width: 18%;
      display: flex;
      flex-wrap: wrap;
      .sta_item_p1_item {
        width: 35%;
        height: 45%;
        p {
          text-align: center;
          line-height: 30px;
          img {
            vertical-align: middle;
            margin-left: 8px;
            position: relative;
            top: -2px;
          }
        }
        .el-progress {
          width: 300px;
        }
        .skyblue {
          font-size: 14px;
          margin-right: 10px;
        }
      }
    }
    .sta_item_p3 {
      width: 26%;
      border-right: 1px solid #ebf0f5;
      .skyblue {
        color: #475669;
        font-weight: 600;
        font-size: 14px;
      }
      .sta_item_p3_item {
        display: flex;
        justify-content: space-between;
        height: 45%;
        .sta_item_p1_item {
          width: 24%;
        }
        p {
          text-align: center;
          line-height: 30px;
        }
      }
    }
    .sta_item_p4 {
      width: 37%;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }
}
</style>